import React, {useState} from "react";
// import ReservationDetails from "./components/ReservationDetae ils";
// import UploadDropzone from "./components/UploadDropzone";
import { LoginPage } from "./components/LoginPage";
import { ReservationsPage } from "./components/ReservationPage";
import { Room } from "colyseus.js";
import 'bootstrap/dist/css/bootstrap.min.css';


export interface Reservation {
  title: string;
  date: string;
  description: string;
}

const App: React.FC = () => {
  const [userAddress, setUserAddress] = useState<string | null>(null);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [room, setRoom] = useState<Room | null>(null); // Colyseus room state

  return (
    <div className="Page-story-container">
            {!userAddress ? (
        <LoginPage
        setUserAddress={setUserAddress}
        setReservations={setReservations}
        setRoom={setRoom} // Pass setRoom to LoginPage
      />
      ) : (
        <ReservationsPage
          userAddress={userAddress}
          reservations={reservations}
          room={room} // Pass room to ReservationsPage
        />      )}
      </div>
  );
};

export default App;
