import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
// import { uploadFiles } from "../utils/api";
import { Button, Header, Icon, Loader, Message, Card, Hero, Table, Modal } from "decentraland-ui";
import {Room} from 'colyseus.js'
import { hashFile, processZipFile } from "../utils/api";
import axios from "axios";
import resources from "../utils/resources";
import { ethers } from "ethers";
import { ProgressBar } from "react-bootstrap";


interface UploadDropzoneProps {
  reservationId: string;
  room:Room | null,
  location:any
  canDeploy:boolean
  userId:string
  addReservation: (reservation: any) => void;
}

const UploadDropzone: React.FC<UploadDropzoneProps> = ({addReservation, reservationId, room, location, canDeploy, userId }) => {
  const [newZip, setZip] = useState<any>(null);
  const [newSize, setSize] = useState(0)
  const [largestFileSize, setLargestFileSize] = useState(0)
  const [error, setError] = useState(null);
  const [errorType, setErrorType] = useState('');
  const [hasError, setHasError] = useState(false);
  const [validating, setValidating] = useState(false)
  const [validationComplete, setValidationComplete] = useState(false)
  const [uploading, setUploading] = useState(false);
  const [filesCounted, setFilesCounted] = useState(0)
  const [deploymentFinished, setDeploymentFinished] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState(0);


  const onDrop = (async (acceptedFiles:any) => {
    const zipFile = acceptedFiles[0]; // Assuming only one file is uploaded
    if (zipFile) {
      console.log("Processing ZIP file:", zipFile.name);
      try {
        setValidationComplete(false)
        setValidating(true)
        
        const {newZipBlob, fileCount, size, largestFileSize, error} = await processZipFile(zipFile, location.parcels.length);
        
        setFilesCounted(fileCount)
        setSize(Math.floor(size/1024/1024))
        setLargestFileSize(Math.floor(largestFileSize/1024/1024))

                // // Create a download link for the new zip file
                // const url = URL.createObjectURL(newZipBlob);
                // const link = document.createElement("a");
                // link.href = url;
                // link.download = "filtered-files.zip";
                // link.click();
        
                console.log("New zip file created and download triggered.");

                if(error){
                  setError(error.msg)
                  setErrorType(error.type)
                  setHasError(true)
                }else{
                  setZip(newZipBlob)
                  console.log('set the zip', newZipBlob)
                }
               
                setValidating(false)
                setValidationComplete(true)

      } catch (error:any) {
        console.error("Error:", error);
        setError(error.message)
        setHasError(true)
        setValidating(false)
        setValidationComplete(true)
      }
    }
  })

  const { getRootProps, getInputProps } = useDropzone({ onDrop, useFsAccessApi:true });

  const uploadAsset = async()=>{
    setUploading(true)
    setUploadPercentage(0)
    try{
      // Step 1: Hash the file
      const hash = await hashFile(newZip);

      // Step 2: Sign the hash
      const signature = await signMessage(hash);
      console.log('sig length = ', signature.length)

      const formData = new FormData()
      formData.append('file', newZip);
      formData.append('userId', userId)
      formData.append('locationId', location.id)
      formData.append('reservationId', reservationId)
      formData.append("signature", signature);
      formData.append("hash", hash);
    
      // Debug: Log FormData content
      console.log("FormData Content:");
      formData.forEach((value, key) => {
        console.log(`${key}:`, value);
      });

      axios
      .post((resources.ENV === "Development" ? "http://localhost:5353" : resources.PROD_SERVER.replace("wss", "https")) + "/upload-scene", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent:any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log('progress is', percentCompleted)
          setUploadPercentage(percentCompleted);
        },
      })
      .then((response:any) => {
        let data = response.data
        if(data.success){
          console.log('upload success')
          addReservation({
            id:data.deploymentId,
            started:Math.floor(Date.now()/1000),
            status:"Queued",
            ended:0
          })
        }else{
          console.log("upload fail")
          setError(data.error)
          setErrorType("Server Error")
          setHasError(true)
        }
  
        setUploading(false)
        setValidating(false)
        setValidationComplete(false)
        setDeploymentFinished(true)
      })
      .catch((error) => {
        console.error("Error:", error)
        setError(error.msg)
        setErrorType(error.type)
        setHasError(true)
        setUploading(false)
        setValidating(false)
        setValidationComplete(false)
        setDeploymentFinished(true)
      });

      // fetch((resources.ENV === "Development" ? "http://localhost:5353" : resources.PROD_SERVER.replace("wss", "https")) + "/upload-scene", {
      //   method: "POST",
      //   body: formData,
      // })
      //   .then((response) => response.json())
      //   .then((data) => {
      //     console.log("Response:", data)
      //     if(data.success){
      //       console.log('upload success')
      //       addReservation({
      //         id:data.deploymentId,
      //         started:Math.floor(Date.now()/1000),
      //         status:"Queued",
      //         ended:0
      //       })
      //     }else{
      //       console.log("upload fail")
      //       setError(data.error)
      //       setErrorType("Server Error")
      //       setHasError(true)
      //     }
    
      //     setUploading(false)
      //     setValidating(false)
      //     setValidationComplete(false)
      //     setDeploymentFinished(true)
      //   })
      //   .catch((error) => {
      //     console.error("Error:", error)
      //     setError(error.msg)
      //     setErrorType(error.type)
      //     setHasError(true)
      //   });
    }
    catch(e:any){
      setUploading(false)
      console.error(error);
      setUploading(false)
        setValidating(false)
        setValidationComplete(false)
        setDeploymentFinished(true)
    }
  }

  const signMessage = async (deployHash: string): Promise<string> => {
    if (!window.ethereum) {
      throw new Error("MetaMask is not installed.");
    }
  
    const provider = new ethers.BrowserProvider(window.ethereum);
    await provider.send("eth_requestAccounts", []); // Request wallet connection
    const signer = await provider.getSigner();
    const address = await signer.getAddress();
  
    const domain = {
      name: "AngzaarPlazaDeployment",
      version: "1",
      chainId: 1,
    };
  
    const types = {
      Deploy: [
        { name: "message", type: "string" },
        { name: "deployHash", type: "string" },
      ],
    };
  
    const value = {
      message: "Sign to upload your content",
      deployHash,
    };
  
    // const signature = await provider.send("eth_signTypedData_v4", [
    //   address,
    //   JSON.stringify({
    //     domain,
    //     types,
    //     primaryType: "Deploy",
    //     message: value,
    //   }),
    // ]);

    // const ver = ethers.verifyTypedData(domain, types, value, signature)
    // console.log('ver is', ver)
  
    return await signer.signMessage(deployHash); // Signs the hash
  };

  const onUploadProgress = (event:any) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);
    console.log('onUploadProgress', percentCompleted);
    // setUploadProgress(percentCompleted)
  };


  return (
    <div>
      <Header size="large">Upload New Scene</Header>


   

      {validating ? 
      <Message >
        <div>Validating Scene...</div>
        <Loader active></Loader>
        </Message>
      :
       
      uploading ? 
      <Message >
        <div>Uploading Scene...</div>
        <Loader active></Loader>
        <ProgressBar now={uploadPercentage} />
        </Message>
      :

      deploymentFinished ? 

      hasError ? 
      <Message error header={"Upload Error"} content={error}></Message>

      :

      <Message success>Your scene was successfully uploaded! Check <a>here</a> for your spot in the deployment queue.</Message>
      : 

        <div>

    <Message className="dropzone" content={
      <div  {...getRootProps()} >
                <input {...getInputProps()} />
                **please note this will overwrite and completely erase your current deployment!**
      </div>
    } 
    header={'Drag & drop your scene zip file here'} />

      {/* <div  {...getRootProps()} className="dcl hero centered dropzone center">
        <Hero.Header>
        <input {...getInputProps()} />
        **please note this will overwrite and completely erase your current deployment!**
        </Hero.Header>
      </div> */
/* 
       <div className="dcl card dropzone">
      <input {...getInputProps()} />
      <div className="dcl card content">
        <div className="dcl card header">Drag & drop your scene zip file here</div>
        <p>      **please note this will overwrite and completely erase your current deployment!**
        </p>
      </div>
    </div>  */}

    {hasError &&
      <Message error content={error} header={'Scene Validation Error'} />
      }

{!hasError && validationComplete && !uploading && 

<Button
  primary
  onClick={uploadAsset}
>
  Upload Scene
</Button>
}


      {validationComplete && 
         <Table basic="very">
               <Table.Header>
                 <Table.Row>
                   <Table.HeaderCell>Type</Table.HeaderCell>
                   <Table.HeaderCell>Value</Table.HeaderCell>
                   <Table.HeaderCell>Limit</Table.HeaderCell>
                   <Table.HeaderCell>Status</Table.HeaderCell>
                 </Table.Row>
               </Table.Header>
         
              <Table.Body>

              {/* <Table.Row>
                <Table.Cell># of files
                </Table.Cell>
                <Table.Cell>{location.id}
                </Table.Cell>
                <Table.Cell>Unlimited
                </Table.Cell>
                <Table.Cell><Message success>PASSED</Message>
                </Table.Cell>
              </Table.Row> */}

              <Table.Row>
                <Table.Cell>Parcel Count
                </Table.Cell>
                <Table.Cell>{location && location.parcels.length}
                </Table.Cell>
                <Table.Cell>{location && location.parcels.length}
                </Table.Cell>
                <Table.Cell>
                  <Message 
                  error={hasError && errorType === "parcels"}
                  success={errorType !== "parcels"}
                  >
                    {hasError && errorType === "parcels" ? "FAIL" : "PASSED"}
                    </Message>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell># of files
                </Table.Cell>
                <Table.Cell>{filesCounted}
                </Table.Cell>
                <Table.Cell>Unlimited
                </Table.Cell>
                <Table.Cell><Message success>PASSED</Message>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Largest File Size
                </Table.Cell>
                <Table.Cell>{largestFileSize}MB
                </Table.Cell>
                <Table.Cell>50MB
                </Table.Cell>
                <Table.Cell>
                  <Message 
                  error={hasError && errorType === "filesize"}
                  success={errorType !== "filesize"}
                  >
                    {hasError && errorType === "filesize" ? "FAIL" : "PASSED"}
                    </Message>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Total Scene Size
                </Table.Cell>
                <Table.Cell>{newSize}MB
                </Table.Cell>
                <Table.Cell>{location && location.parcels.length > 12 ? 300 : location.parcels.length * 15}MB
                </Table.Cell>
                <Table.Cell>
                  <Message 
                  error={errorType === "scenesize"}
                  success={errorType !== "scenesize"}
                  >
                    {hasError && errorType === "scenesize" ? "FAIL" : "PASSED"}
                    </Message>
                </Table.Cell>
              </Table.Row>

               </Table.Body>
             </Table>}
      </div>
     }


    </div>
  );
};

export default UploadDropzone;
