import React, { useEffect,useState } from "react";
import { Container, Center, Page, Tab, Message, Tabs,Button, Modal, Blockie, Address, Table, Loader, Hero, HeroProps, Atlas, Layer, AtlasTile, Header } from "decentraland-ui";
import {Row, Col} from 'react-bootstrap'
import { Room } from "colyseus.js";
import { Reservation } from "../App";
import UploadDropzone from "./UploadDropone";
import { formatUnixTimestamp } from "../utils/api";

interface ReservationsPageProps {
  userAddress: string;
  reservations: Reservation[];
  room: Room | null;
}

interface Tile {
  x: number;
  y: number;
  type: number;
  left?: number;
  top?: number;
  topLeft?: number;
  owner: string;
  name?: string;
  estate_id?: string;
};

interface TileLayer {
  color: string;
  top?: boolean;
  left?: boolean;
  topLeft?: boolean;
  scale?: number;
};

interface Location {
  id: number;
  name: string;
  description: string;
  image: string;
  email: string;
  owner: string;
  parcels: string[]; // Parcel coordinates as strings (e.g., "35,45")
}


export const ReservationsPage: React.FC<ReservationsPageProps> = ({ userAddress, reservations, room }) => {
  const [tiles, setTiles] = useState<Record<string, Tile>>({});
  const [layers, setLayers] = useState<Layer[]>([]);
  const [selectedTile, setSelectedTile] = useState<Tile | null>(null);
  const [reservedParcels, setReservedParcels] = useState<string[]>([])
  const [reservationId, setReservationId] = useState('')
  const [location, setLocation] = useState<any>(null)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [reservationDeployments, setReservationDeployments] = useState<any>([])
  const [cancelingReservation, setCancelingReservation] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)
  const [success, setSuccess] = useState(false)
  const [reservation, setUserReservation] = useState<any>(null)
  const [canDeploy, setCanDeploy] = useState(false)

  const addReservation = (newReservation: any) => {
    setReservationDeployments((prevReservations:any) => [...prevReservations, newReservation]);
  };

  const isReserved = (x:any, y:any) =>{
    let parcel:string = x + ',' + y
    return reservedParcels.includes(parcel) ? { color: '#f311f6', scale: 1.2 } : null
    }  

  
  const isNotAngzaar = (x:any, y:any) =>{
    let parcel:string = x + ',' + y
    return !angzaarPlazaParcels.includes(parcel) ? { color: '#ffffff', scale: 1.15} : null
    }  

    const isArtDistrict = (x:any, y:any) =>{
      let parcel:string = x + ',' + y
      return artParcels.includes(parcel) ? { color: '#f11fd3', scale: 1} : null
      }  

    const isVenue = (x:any, y:any) =>{
      let parcel:string = x + ',' + y
      return venueParcels.includes(parcel) ? { color: '#f9b321', scale: 1} : null
      }  

      const isCC = (x:any, y:any) =>{
        let parcel:string = x + ',' + y
        return ccParcels.includes(parcel) ? { color: '#0051a3', scale: 1} : null
        }  

       const isRoad = (x:any, y:any) =>{
        let parcel:string = x + ',' + y
        return roadParcels.includes(parcel) ? { color: '#797787', scale: 1} : null
        }  

        
  useEffect(() => {
    if (room) {
      room.send("get-reservation")

      room.onMessage("error", (message) => {
        console.log("Received error:", message);
      });

      // Listen to specific messages
      room.onMessage("user-reservation", (message) => {
        console.log("Received message:", message);

        if(!message.location){
          return
        }

        if(message.deployments){
          setReservationDeployments(message.deployments)
        }

        if(message.userReservation){
          setUserReservation(message.userReservation)

          let now = Date.now()/1000
          if(message.userReservation.startDate < now && message.userReservation.endDate > now){
            setCanDeploy(true)
          }
     
        }

        setReservedParcels(message.location.parcels)
        setReservationId(message.userReservation.id)
        setLocation(message.location)
        setSuccess(true)
        setLoadingPage(false)

        console.log('message location is', message.location)
      });

      room.onMessage("cancel-reservation", (message) => {
        console.log("Received cancel message:", message);
        if(message.reservation.ethAddress === userAddress){
          console.log('local user canceled')
          setSuccess(false)
        }
      })

      room.onMessage("no-reservation", (message) => {
        console.log("Received no user message:", message);
        setLoadingPage(false)
      })

      room.onMessage("no-reserve", (message) => {
        console.log("Received no server reservations message:", message);
        setLoadingPage(false)
      })

      room.onMessage("get-deployments", (message) => {
        console.log("Received get deployments message:", message);
        setReservationDeployments(message)
      })

      Atlas.fetchTiles().then(_tiles => {
        setTiles(_tiles)
      })

      // Cleanup on unmount
      return () => {
        // room.leave();
      };
    }
  }, [room]);

  const cancelReservation = ()=>{
    setCancelingReservation(true); 
    setShowCancelModal(false)
    room?.send("cancel-reservation", {locationId:location.id})
  }

  const refreshDeployments = ()=>{
    room?.send("get-deployments", {reservationId:reservationId})
  }

  return (

    <div className="dcl page fullscreen">
            <div className="dcl.modal-story.centered">
      <Modal open={showCancelModal}>
        <Modal.Header>Cancel Reservation?</Modal.Header>
        <Modal.Content>
          You are about to cancel your reservation for this land! You will lose all deployment rights and you will not be refunded if you paid for a premium reservation.
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={cancelReservation}>Proceed</Button>
          <Button onClick={()=>{setShowCancelModal(false)}}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    </div>
    <Tabs>
    <Tabs.Tab >Angzaar Plaza</Tabs.Tab>
      <Tabs.Tab active>Your Reservation</Tabs.Tab>
      <Tabs.Tab>
      <Blockie scale={3} seed={userAddress}>
        <Address value={userAddress} strong />
      </Blockie>
      </Tabs.Tab>
   </Tabs>

   {loadingPage ? 
   <Loader active>Loading...</Loader>
    :

    success ? 

   <div>
   <Row>
    <Col style={{ padding:'0 5% 0 5%'}}>
    <Atlas tiles={tiles} layers={[isReserved, isNotAngzaar,  isRoad ]} x={1} y={-82} zoom={1.7} isDraggable={false}/>
    </Col>

    <Col style={{ padding:'0 5% 0 5%'}}>
    <Header size="large">Reservation Details</Header>

         <Table basic="very">
               <Table.Header>
                 <Table.Row>
                   <Table.HeaderCell>Type</Table.HeaderCell>
                   <Table.HeaderCell>Value</Table.HeaderCell>
                 </Table.Row>
               </Table.Header>
         
              <Table.Body>

              <Table.Row>
                <Table.Cell>Approved Wallet
                </Table.Cell>
                <Table.Cell>{reservation && reservation.ethAddress}
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Parcels ({reservedParcels.length})
                </Table.Cell>
                <Table.Cell>{
                  reservedParcels.map(str => `[${str}]`)
                  }
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Start Date
                </Table.Cell>
                <Table.Cell>{reservation ? formatUnixTimestamp(reservation.startDate) : ""}
                </Table.Cell>
              </Table.Row>
              
              <Table.Row>
                <Table.Cell>End Date
                </Table.Cell>
                <Table.Cell>{reservation ? formatUnixTimestamp(reservation.endDate) : ""}
                </Table.Cell>
              </Table.Row>

                  { !cancelingReservation && 
              <Table.Row>
                <Table.Cell>
                <Button primary onClick={()=>{setShowCancelModal(true)}}>Cancel Reservation</Button>
                </Table.Cell>
              </Table.Row>
                }

               </Table.Body>
          </Table>

                { canDeploy ? 
    <UploadDropzone addReservation={addReservation} canDeploy={canDeploy} userId={userAddress} reservationId={reservationId} location={location} room={room} />

    : 

    <div>
            <Header size="large">Upload New Scene</Header>
            <Message >
        <div>Deployment availability only during your reserved days.</div>
        </Message>
    </div>
    }
    </Col>
   </Row>


   <Row>
   <Col style={{ padding:'1% 5% 5% 5%'}}>
    <Header size="large">Previous Reservation Deployments</Header>
    <Button
  primary
  onClick={refreshDeployments}
>
  Refresh
</Button>
<Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Deployment Id</Table.HeaderCell>
          <Table.HeaderCell>Deployed At</Table.HeaderCell>
          {/* <Table.HeaderCell>Deployed Time</Table.HeaderCell> */}
          <Table.HeaderCell>Deployed Status</Table.HeaderCell>
          <Table.HeaderCell>Deployed Message</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

     <Table.Body>

     {reservationDeployments.map((deployment:any) => (
     <Table.Row key={deployment.id}>
     <Table.Cell>{deployment.id}
     </Table.Cell>
     <Table.Cell>{formatUnixTimestamp(deployment.started)}
     </Table.Cell>
     {/* <Table.Cell>{deployment.ended - deployment.started} seconds
     </Table.Cell> */}
     <Table.Cell>{deployment.status}
     </Table.Cell>
     {  deployment.finished ? 
          <Table.Cell><Message success={!deployment.error} error={deployment.error} >
          {deployment.error ? "FAIL" : "SUCCESS"}
          </Message>
          </Table.Cell>
          :

          null
     }
     
   </Table.Row>
      ))}
      </Table.Body>
 </Table>
 </Col>
   </Row>
   </div>

   :

   <div>
    <div className="dcl hero centered">
        <Hero.Header>No Reservations!</Hero.Header>
        <Hero.Description><a href="#">Jump IN!</a> and starting building!</Hero.Description>
      </div>
   </div>

    }

    
</div>
  );
};


let angzaarPlazaParcels:any= [
  "-9,-91",
  "-9,-90",
  "-9,-89",
  "-9,-88",
  "-9,-87",
  "-9,-86",
  "-9,-85",
  "-9,-84",
  "-9,-83",
  "-9,-82",
  "-9,-81",
  "-9,-80",
  "-9,-79",
  "-9,-78",
  "-9,-77",
  "-9,-76",
  "-9,-75",
  "-9,-74",
  "-9,-73",
  "-9,-72",
  "-8,-91",
  "-8,-90",
  "-8,-89",
  "-8,-88",
  "-8,-87",
  "-8,-86",
  "-8,-85",
  "-8,-84",
  "-8,-83",
  "-8,-82",
  "-8,-81",
  "-8,-80",
  "-8,-79",
  "-8,-78",
  "-8,-77",
  "-8,-76",
  "-8,-75",
  "-8,-74",
  "-8,-73",
  "-8,-72",
  "-7,-91",
  "-7,-90",
  "-7,-89",
  "-7,-88",
  "-7,-87",
  "-7,-86",
  "-7,-85",
  "-7,-84",
  "-7,-83",
  "-7,-82",
  "-7,-81",
  "-7,-80",
  "-7,-79",
  "-7,-78",
  "-7,-77",
  "-7,-76",
  "-7,-75",
  "-7,-74",
  "-7,-73",
  "-7,-72",
  "-6,-91",
  "-6,-90",
  "-6,-89",
  "-6,-88",
  "-6,-87",
  "-6,-86",
  "-6,-85",
  "-6,-84",
  "-6,-83",
  "-6,-82",
  "-6,-81",
  "-6,-80",
  "-6,-79",
  "-6,-78",
  "-6,-77",
  "-6,-76",
  "-6,-75",
  "-6,-74",
  "-6,-73",
  "-6,-72",
  "-5,-91",
  "-5,-90",
  "-5,-89",
  "-5,-88",
  "-5,-87",
  "-5,-86",
  "-5,-85",
  "-5,-84",
  "-5,-83",
  "-5,-82",
  "-5,-81",
  "-5,-80",
  "-5,-79",
  "-5,-78",
  "-5,-77",
  "-5,-76",
  "-5,-75",
  "-5,-74",
  "-5,-73",
  "-5,-72",
  "-4,-91",
  "-4,-90",
  "-4,-89",
  "-4,-88",
  "-4,-87",
  "-4,-86",
  "-4,-85",
  "-4,-84",
  "-4,-83",
  "-4,-82",
  "-4,-81",
  "-4,-80",
  "-4,-79",
  "-4,-78",
  "-4,-77",
  "-4,-76",
  "-4,-75",
  "-4,-74",
  "-4,-73",
  "-4,-72",
  "-3,-91",
  "-3,-90",
  "-3,-89",
  "-3,-88",
  "-3,-87",
  "-3,-86",
  "-3,-85",
  "-3,-84",
  "-3,-83",
  "-3,-82",
  "-3,-81",
  "-3,-80",
  "-3,-79",
  "-3,-78",
  "-3,-77",
  "-3,-76",
  "-3,-75",
  "-3,-74",
  "-3,-73",
  "-3,-72",
  "-2,-91",
  "-2,-90",
  "-2,-89",
  "-2,-88",
  "-2,-87",
  "-2,-86",
  "-2,-85",
  "-2,-84",
  "-2,-83",
  "-2,-82",
  "-2,-81",
  "-2,-80",
  "-2,-79",
  "-2,-78",
  "-2,-77",
  "-2,-76",
  "-2,-75",
  "-2,-74",
  "-2,-73",
  "-2,-72",
  "-1,-91",
  "-1,-90",
  "-1,-89",
  "-1,-88",
  "-1,-87",
  "-1,-86",
  "-1,-85",
  "-1,-84",
  "-1,-83",
  "-1,-82",
  "-1,-81",
  "-1,-80",
  "-1,-79",
  "-1,-78",
  "-1,-77",
  "-1,-76",
  "-1,-75",
  "-1,-74",
  "-1,-73",
  "-1,-72",
  "0,-91",
  "0,-90",
  "0,-89",
  "0,-88",
  "0,-87",
  "0,-86",
  "0,-85",
  "0,-84",
  "0,-83",
  "0,-82",
  "0,-81",
  "0,-80",
  "0,-79",
  "0,-78",
  "0,-77",
  "0,-76",
  "0,-75",
  "0,-74",
  "0,-73",
  "0,-72",
  "1,-91",
  "1,-90",
  "1,-89",
  "1,-88",
  "1,-87",
  "1,-86",
  "1,-85",
  "1,-84",
  "1,-83",
  "1,-82",
  "1,-81",
  "1,-80",
  "1,-79",
  "1,-78",
  "1,-77",
  "1,-76",
  "1,-75",
  "1,-74",
  "1,-73",
  "1,-72",
  "2,-91",
  "2,-90",
  "2,-89",
  "2,-88",
  "2,-87",
  "2,-86",
  "2,-85",
  "2,-84",
  "2,-83",
  "2,-82",
  "2,-81",
  "2,-80",
  "2,-79",
  "2,-78",
  "2,-77",
  "2,-76",
  "2,-75",
  "2,-74",
  "2,-73",
  "2,-72",
  "3,-91",
  "3,-90",
  "3,-89",
  "3,-88",
  "3,-87",
  "3,-86",
  "3,-85",
  "3,-84",
  "3,-83",
  "3,-82",
  "3,-81",
  "3,-80",
  "3,-79",
  "3,-78",
  "3,-77",
  "3,-76",
  "3,-75",
  "3,-74",
  "3,-73",
  "3,-72",
  "4,-91",
  "4,-90",
  "4,-89",
  "4,-88",
  "4,-87",
  "4,-86",
  "4,-85",
  "4,-84",
  "4,-83",
  "4,-82",
  "4,-81",
  "4,-80",
  "4,-79",
  "4,-78",
  "4,-77",
  "4,-76",
  "4,-75",
  "4,-74",
  "4,-73",
  "4,-72",
  "5,-91",
  "5,-90",
  "5,-89",
  "5,-88",
  "5,-87",
  "5,-86",
  "5,-85",
  "5,-84",
  "5,-83",
  "5,-82",
  "5,-81",
  "5,-80",
  "5,-79",
  "5,-78",
  "5,-77",
  "5,-76",
  "5,-75",
  "5,-74",
  "5,-73",
  "5,-72",
  "6,-91",
  "6,-90",
  "6,-89",
  "6,-88",
  "6,-87",
  "6,-86",
  "6,-85",
  "6,-84",
  "6,-83",
  "6,-82",
  "6,-81",
  "6,-80",
  "6,-79",
  "6,-78",
  "6,-77",
  "6,-76",
  "6,-75",
  "6,-74",
  "6,-73",
  "6,-72",
  "7,-91",
  "7,-90",
  "7,-89",
  "7,-88",
  "7,-87",
  "7,-86",
  "7,-85",
  "7,-84",
  "7,-83",
  "7,-82",
  "7,-81",
  "7,-80",
  "7,-79",
  "7,-78",
  "7,-77",
  "7,-76",
  "7,-75",
  "7,-74",
  "7,-73",
  "7,-72",
  "8,-91",
  "8,-90",
  "8,-89",
  "8,-88",
  "8,-87",
  "8,-86",
  "8,-85",
  "8,-84",
  "8,-83",
  "8,-82",
  "8,-81",
  "8,-80",
  "8,-79",
  "8,-78",
  "8,-77",
  "8,-76",
  "8,-75",
  "8,-74",
  "8,-73",
  "8,-72",
  "9,-91",
  "9,-90",
  "9,-89",
  "9,-88",
  "9,-87",
  "9,-86",
  "9,-85",
  "9,-84",
  "9,-83",
  "9,-82",
  "9,-81",
  "9,-80",
  "9,-79",
  "9,-78",
  "9,-77",
  "9,-76",
  "9,-75",
  "9,-74",
  "9,-73",
  "9,-72",
  "10,-91",
  "10,-90",
  "10,-89",
  "10,-88",
  "10,-87",
  "10,-86",
  "10,-85",
  "10,-84",
  "10,-83",
  "10,-82",
  "10,-81",
  "10,-80",
  "10,-79",
  "10,-78",
  "10,-77",
  "10,-76",
  "10,-75",
  "10,-74",
  "10,-73",
  "10,-72"
]

let artParcels:any = [
  "2,-78",
  "2,-77",
  "2,-76",
  "2,-75",
  "2,-74",
  "2,-73",
  "2,-72",
  "3,-78",
  "3,-77",
  "3,-76",
  "3,-75",
  "3,-74",
  "3,-73",
  "3,-72",
  "4,-78",
  "4,-77",
  "4,-76",
  "4,-75",
  "4,-74",
  "4,-73",
  "4,-72",
  "5,-78",
  "5,-77",
  "5,-76",
  "5,-75",
  "5,-74",
  "5,-73",
  "5,-72",
  "6,-78",
  "6,-77",
  "6,-76",
  "6,-75",
  "6,-74",
  "6,-73",
  "6,-72",
  "7,-78",
  "7,-77",
  "7,-76",
  "7,-75",
  "7,-74",
  "7,-73",
  "7,-72",
  "8,-78",
  "8,-77",
  "8,-76",
  "8,-75",
  "8,-74",
  "8,-73",
  "8,-72",
  "9,-78",
  "9,-77",
  "9,-76",
  "9,-75",
  "9,-74",
  "9,-73",
  "9,-72",
  "10,-78",
  "10,-77",
  "10,-76",
  "10,-75",
  "10,-74",
  "10,-73",
  "10,-72"
]

let venueParcels:any = [
  "2,-86",
  "2,-85",
  "2,-84",
  "2,-83",
  "2,-82",
  "2,-81",
  "2,-80",
  "3,-86",
  "3,-85",
  "3,-84",
  "3,-83",
  "3,-82",
  "3,-81",
  "3,-80",
  "4,-86",
  "4,-85",
  "4,-84",
  "4,-83",
  "4,-82",
  "4,-81",
  "4,-80",
  "5,-86",
  "5,-85",
  "5,-84",
  "5,-83",
  "5,-82",
  "5,-81",
  "5,-80",
  "6,-86",
  "6,-85",
  "6,-84",
  "6,-83",
  "6,-82",
  "6,-81",
  "6,-80"
]

let ccParcels:any = [
  "7,-86",
  "7,-85",
  "7,-84",
  "7,-83",
  "7,-82",
  "7,-81",
  "7,-80",
  "8,-86",
  "8,-85",
  "8,-84",
  "8,-83",
  "8,-82",
  "8,-81",
  "8,-80",
  "9,-86",
  "9,-85",
  "9,-84",
  "9,-83",
  "9,-82",
  "9,-81",
  "9,-80",
  "10,-86",
  "10,-85",
  "10,-84",
  "10,-83",
  "10,-82",
  "10,-81",
  "10,-80"
]

let roadParcels:any = [
  "-9,-87",
  "-9,-86",
  "-9,-81",
  "-9,-76",
  "-8,-87",
  "-8,-86",
  "-8,-81",
  "-8,-76",
  "-4,-83",
  "-7,-91",
  "-7,-90",
  "-7,-89",
  "-7,-88",
  "-7,-87",
  "-7,-86",
  "-7,-85",
  "-7,-84",
  "-7,-83",
  "-7,-82",
  "-7,-81",
  "-7,-80",
  "-7,-79",
  "-7,-78",
  "-7,-77",
  "-7,-76",
  "-7,-75",
  "-7,-74",
  "-7,-73",
  "-7,-72",
  
  "-6,-87",
  "-6,-83",
  "-6,-79",
  "-6,-75",
  "-5,-87",
  "-5,-79",
  "-5,-75",
  "-5,-83",
  "-4,-79",
  "-4,-75",
  "-4,-91",
  "-4,-90",
  "-4,-89",
  "-4,-88",
  "-4,-87",
  
  "-3,-87",
  "-3,-86",
  "-3,-85",
  "-3,-84",
  "-3,-83",
  "-3,-82",
  "-3,-81",
  "-3,-80",
  "-3,-79",
  "-3,-78",
  "-3,-77",
  "-3,-76",
  "-3,-75",
  "-3,-74",
  "-3,-73",
  "-3,-72",

  "-2,-87",
  "-2,-83",
  "-2,-79",
  "-2,-75",

  "-1,-87",
  "-1,-83",
  "-1,-79",
  "-1,-75",

  "0,-87",
  "0,-83",
  "0,-79",
  "0,-75",

  "-1,-91",
  "-1,-90",
  "-1,-89",
  "-1,-88",

  "1,-87",
  "1,-86",
  "1,-85",
  "1,-84",
  "1,-83",
  "1,-82",
  "1,-81",
  "1,-80",
  "1,-79",
  "1,-78",
  "1,-77",
  "1,-76",
  "1,-75",
  "1,-74",
  "1,-73",
  "1,-72",

  "2,-91",
  "2,-90",
  "2,-89",
  "2,-88",
  "2,-87",

  "5,-91",
  "5,-90",
  "5,-89",
  "5,-88",

  "8,-91",
  "8,-90",
  "8,-89",
  "8,-88",


  "3,-87",
  "4,-87",
  "5,-87",
  "6,-87",
  "7,-87",
  "8,-87",
  "9,-87",
  "10,-87",


  "2,-79",
  "3,-79",
  "4,-79",
  "5,-79",
  "6,-79",
  "7,-79",
  "8,-79",
  "9,-79",
  "10,-79",

  "6,-86",
  "6,-85",
  "6,-84",
  "6,-83",
  "6,-82",
  "6,-81",
  "6,-80"
  
]